import React from 'react';
import igv from 'igv';
import GeneDropdown from '../components/GeneDropdown.js';

import {
  Button,
  Icon,
  SegmentedControlButton,
  SegmentedControl,
} from '@calico/calico-ui-kit';

let configs = {
  hg38: require('../content/configs/wi38.json'),
  wi38: require('../content/configs/wi38_hybrid.json')
};

let displayNames = {
  hg38: 'GRCh38',
  wi38: 'WI-38 Hybrid',
}

let coords = {
  hg38: new igv.FeatureSource({
    name: 'hg38_to_wi38',
    url: 'https://storage.googleapis.com/calico-wi38-assembly-public/browser/hg38.to_wi38.bed.gz',
    indexed: true
  }, undefined),
  wi38: new igv.FeatureSource({
    name: 'wi38_to_hg38',
    url: 'https://storage.googleapis.com/calico-wi38-assembly-public/browser/wi38.to_hg38.bed.gz',
    indexed: true
  }, undefined)
};

let locusStringToObj = function (locusString) {
  let a = locusString.split(':');
  let b = a[1].replace(/,/g, '').split('-');
  return {
    chromosome: a[0],
    start: parseInt(b[0]),
    end: parseInt(b[1])
  }
}

let locusObjToString = function (locusObj) {
  return `${locusObj.chromosome}:${locusObj.start}-${locusObj.end}`;
}

let padLocus = function (locusObj, pad) {
  if (locusObj.end - locusObj.start >= pad * 2) {
    return locusObj
  } else {
    let mid = Math.round((locusObj.end + locusObj.start) / 2);
    return {
      chromosome: locusObj.chromosome,
      start: Math.max(mid - pad, 0),
      end: mid + pad
    }
  }
}

let geneFiles = {
  hg38: './genelists/wi38.json',
  wi38: './genelists/wi38_hybrid.json'
};

class BrowserPage extends React.Component {
  constructor(props) {
    super(props);
    this.browser = null;
    this.locus = 'chr1:1-248,956,422';
    this.igvContainer = React.createRef();
    this.state = {
      currentGenome: 'hg38',
      geneLists: {},
      shouldShowExplanation: false,
    };
  }

  savePermalink = () => {
    let link = this.browser.sessionURL();
    window.history.pushState({}, "IGV", link);
    alert('URL created, bookmark to save');
  }

  saveShortlink = () => {
    let baseUrl = window.location.href.split('?')[0];
    let link = `${baseUrl}?genome=${this.state.currentGenome}&locus=${this.locus}`;
    window.history.pushState({}, "IGV", link);
    alert('URL created, bookmark to save');
  }

  switchReference = (nextGenome: string) => {
    const { currentGenome } = this.state;
    let newGenome = configs[nextGenome] ? nextGenome : 'hg38';
    let chrom, start, width;
    if (this.locus == null) {
      chrom = 'all';
    } else {
      let currLocus = locusStringToObj(this.locus);
      chrom = currLocus.chromosome;
      start = currLocus.start;
      width = currLocus.end - currLocus.start;
    }

    let config = configs[newGenome];
    this.locus = null; // clear existing locus
    if (chrom === 'all') {
      // not zoomed in - just switch genome
      this.browser.loadSession(config).then(success => { })
      this.setState({ currentGenome: newGenome });
    } else {
      let query = start + width / 2;
      let qStart = query - 500, qEnd = query + 500;
      coords[currentGenome].getFeatures(chrom, qStart, qEnd)
        .then(features => {
          if (features.length > 0) {
            let destStr = features[0].name;
            let destObj = locusStringToObj(destStr);
            let padding = Math.max(width / 2, 5000);
            this.locus = locusObjToString(padLocus(destObj, padding));
            config['locus'] = this.locus;
            this.browser.loadSession(config).then(success => { })
            this.setState({ currentGenome: newGenome });
          } else {
            alert('Your position on the current reference does not map to a position on the other reference. Try scrolling until the center line overlaps a covered region with no structural variants.');
          }
        });
    }
  }

  fetchGeneLists = async () => {
    let geneLists = {};
    for (let g in geneFiles) {
      geneLists[g] = await fetch(geneFiles[g])
        .then(response => { return response.json(); })
    }
    this.setState({ geneLists })
  }

  componentDidMount() {
    // query params take precedence over state
    let params = new URLSearchParams(window.location.search);
    let genome = params.get('genome') || this.state.currentGenome;
    let locus = params.get('locus') || this.locus;

    // create browser
    let config = configs[genome];
    config['locus'] = locus;
    igv.createBrowser(this.igvContainer.current, config).then(browser => {
      this.browser = browser;
      this.browser.showCenterGuide();
      this.browser.on('locuschange', referenceFrame => {
        this.locus = referenceFrame.label;
      })
    })

    // load gene lists async
    this.fetchGeneLists();
  }

  render () {
    const {
      currentGenome,
      geneLists,
      shouldShowExplanation,
    } = this.state;
    let currTracks = configs[currentGenome].tracks;
    let geneList = geneLists[currentGenome] || {};
    let geneNames = Object.keys(geneList);
    return (
      <div>
        <section className="padding-2">
          <h4>
            Explore the WI-38 Genome&nbsp;
            <span className='normal'>
              —&nbsp;
              <a
                className='normal cursor-pointer'
                onClick={() => this.setState({ shouldShowExplanation: !shouldShowExplanation })}
              >
                {shouldShowExplanation ? 'hide' : 'show'} explanation
              </a>
            </span>
          </h4>
          
          {shouldShowExplanation &&
            <section>
              <p>Here you can explore the WI-38 genome from two perspectives:</p>
              <ol>
                <li><b>GRCh38:</b> phased WI-38 scaffolds aligned to the human reference,
                  with corresponding phased variants called between our assembly and the reference.</li>
                <li><b>WI-38 Hybrid:</b> phased scaffolds aligned to our unphased hybrid assembly,
                  with corresponding phased variants called from sequencing data (10x and Pac Bio)
                  aligned to the assembly.</li>
              </ol>
              <p>This browser was built with <a href='https://github.com/igvteam/igv.js/'>igv.js.</a></p>
            </section>
          }
        </section>

        <section className="padding-left-2 padding-right-2 flex-row space-between vertical-center font-size-1">
          <section className="flex-1 flex-row">
            <GeneDropdown
              geneNames={geneNames}
              onGeneSelect={geneName => {
                let geneLocus = geneList[geneName][0];
                if (geneLocus) {
                  this.browser.search(locusObjToString(geneLocus));
                } else {
                  alert(`No data for gene: ${geneName}`);
                };
              }}
            />
          </section>
          <section
            className="flex-1 flex-row text-center"
            style={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div title="Toggle between reference genomes, translating the current genomic position accordingly">
              <SegmentedControl
                active={currentGenome}
                onClick={this.switchReference}
                style={{ minHeight: 0 }}
              >
                <SegmentedControlButton id="hg38" title={displayNames['hg38']} />
                <SegmentedControlButton id="wi38" title={displayNames['wi38']} />
              </SegmentedControl>
            </div>
          </section>
          <section
            className="flex-1 flex-row"
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div title="Create a permanent URL saving the current genomic position and track list">
              <Button
                className="browser-button outline margin-right-1"
                title="Create permalink"
                onClick={this.savePermalink}
              />
            </div>
            <div title="Create a short URL saving the current genomic position and reference genome">
              <Button
                className="browser-button outline"
                title="Create shortlink"
                onClick={this.saveShortlink}
              />
            </div>
          </section>
        </section>

        <section className="margin-top-2 padding-2">
          <aside className="flex-row space-between vertical-center font-size-1">
            <section
              className="flex-row"
              style={{
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <div className="tooltip font-size-small">
                <div className="grey flex-row vertical-center relative cursor-pointer select-none">
                  <div className="margin-right-1">Track descriptions</div>
                  <Icon icon="ios-information-circle-outline" fontSize="1.35em" />
                </div>
                <span className='tooltiptext padding-1 font-size-1' style={{ textAlign: 'left', minWidth: 320, maxWidth: '30vw' }}>
                  {currTracks.map((track, key) => {
                    if (track.name) {
                      return (
                        <div className="padding-1" key={key}><b>{track.name}</b>: {track.description}</div>
                      );
                    }
                    return null;
                  })}
                </span>
              </div>
            </section>
            <section
              className="flex-1 flex-row"
              style={{
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div className="grey font-size-small margin-right-1">Phasing legend</div>
              <ul className="legend-labels flex-row">
                <li style={{ background: '#000000', color: 'white' }}>Homozygous</li>
                <li style={{ background: '#1F77B4', color: 'white' }}>Homolog 1</li>
                <li style={{ background: '#FF7F0E', color: 'white' }}>Homolog 2</li>
                <li style={{ background: '#7F7F7F', color: 'white' }}>Heterozygous (unknown phase)</li>
              </ul>
            </section>
          </aside>
          <main
            className="igv margin-top-2"
            ref={this.igvContainer}
          />
        </section>
      </div>
    )
  }
}

export default BrowserPage;
