import React from 'react';
import ReactGA from 'react-ga';

const getComponentName = (Component) => {
  return `${Component.displayName || Component.name || 'Component'}`;
}

function withAnalytics(WrappedComponent, options = {}) {
  class WithAnalytics extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      this.trackPageview(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;
      if (currentPage !== nextPage) {
        this.trackPageview(nextPage);
      }
    }

    trackDownload = (dataset) => {
      this.trackEvent({
        category: 'Data',
        action: 'Download dataset',
        label: dataset || 'Unknown dataset'
      });
    }

    trackEvent = ({ category, action, label }) => {
      ReactGA.event({
        category: category || 'Unknown',
        action: action || 'Unknown',
        label: label || 'Unknown',
      });
    }

    trackPageview = (page) => {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.pageview(page);
    }

    render() {
      return (
        <WrappedComponent
          trackDownload={this.trackDownload}
          trackEvent={this.trackEvent}
          trackPageview={this.trackPageview}
          {...this.props}
        />
      );
    }
  }

  WithAnalytics.displayName = `withAnalytics(${getComponentName(WrappedComponent)})`;

  return WithAnalytics;
}

export default withAnalytics;