import React from 'react';

const GCS_BUCKET_URL = 'https://storage.googleapis.com/calico-wi38-assembly-public';

export default [
  {
    name: 'Assembly statistics',
    size: '5.7 MB',
    src: `${GCS_BUCKET_URL}/downloads/calico_wi38_assembly_stats.xlsx`,
    title: 'Download XLSX',
    content: (
      <div className="max-line-length">
        Excel spreadsheet containing summary statistics of the assembly, as well as lists of structural variants called using both GRCh38 and the WI-38 hybrid (pre-phased) assembly as references.
      </div>
    ),
  },
  {
    name: 'Hybrid assembly sequence',
    size: '823 MB',
    src: `${GCS_BUCKET_URL}/downloads/calico_wi38_assembly_hybrid.fasta.gz`,
    title: 'Download compressed FASTA',
    content: (
      <div className="max-line-length">
        FASTA file (compressed with <span style={{ fontFamily: 'monospace' }}>gzip</span>) containing sequences of all scaffolds from the hybrid (pre-phased) assembly.
      </div>
    ),
  },
  {
    name: 'Phased assembly sequence',
    size: '1.7 GB',
    src: `${GCS_BUCKET_URL}/downloads/calico_wi38_assembly_diploid.fasta.gz`,
    title: 'Download compressed FASTA',
    content: (
      <div className="max-line-length">
        FASTA file (compressed with <span style={{ fontFamily: 'monospace' }}>gzip</span>) containing sequences of all scaffolds from both haplotypes (denoted hap1/hap2) of the phased assembly.
      </div>
    ),
  },
  {
    name: 'Phased variants from assembly-to-reference alignment',
    size: '58 MB',
    src: `${GCS_BUCKET_URL}/downloads/calico_wi38_diploid_assembly_to_hg38.zip`,
    title: 'Download ZIP',
    content: (
      <div className="max-line-length">
        ZIP archive of variant calls (VCF file compressed and indexed with <span style={{ fontFamily: 'monospace' }}>bgzip / tabix</span>) from the alignment of our diploid assembly to the human reference (GRCh38), phased according to their parental homolog.
      </div>
    ),
  },
  {
    name: 'Phased variants from read-to-assembly alignment',
    size: '89 MB',
    src: `${GCS_BUCKET_URL}/downloads/calico_wi38_reads_to_hybrid_assembly.zip`,
    title: 'Download ZIP',
    content: (
      <div className="max-line-length">
        ZIP archive of variant calls (VCF file compressed and indexed with <span style={{ fontFamily: 'monospace' }}>bgzip / tabix</span>) from the alignment of sequencing reads (from 10x Genomics Chromium and Pacific Biosciences RSII platforms) to the WI-38 hybrid (pre-phased) assembly.
      </div>
    ),
  }
];
