import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Colors } from '@calico/calico-ui-kit';
import {
  AuthorList,
  CalicoResearchFooter,
  CalicoResearchHeader,
  Citation,
  Navigation,
  Title,
} from '@calico/calico-ui-editorial';

import Authors from './content/Authors';
import DownloadsPage from './pages/DownloadsPage.js';
import HomePage from './pages/HomePage.js';
import MethodsPage from './pages/MethodsPage.js';
import BrowserPage from './pages/BrowserPage.js';
import withAnalytics from './components/withAnalytics.js';

const ComposedBrowserPage = withRouter(withAnalytics(BrowserPage));
const ComposedDownloadsPage = withRouter(withAnalytics(DownloadsPage));
const ComposedHomePage = withRouter(withAnalytics(HomePage));
const ComposedMethodsPage = withRouter(withAnalytics(MethodsPage));

const CITATION = 'Fully Phased Sequence of a Diploid Human Genome Determined de Novo from the DNA of a Single Individual. Ilya Soifer, Nicole L. Fong, Nelda Yi, Andrea T. Ireland, Irene Lam, Matthew Sooknah, Jonathan S. Paw, Paul Peluso, Gregory T. Concepcion, David Rank, Alex R. Hastie, Vladimir Jojic, J. Graham Ruby, David Botstein, Margaret A. Roy*. G3: GENES, GENOMES, GENETICS September 1, 2020 vol. 10 no. 9 2911-2925; https://doi.org/10.1534/g3.119.400995';
const DOI = 'https://doi.org/10.1534/g3.119.400995';
const CITATION_TITLE = 'Fully Phased Sequence of a Diploid Human Genome Determined de Novo from the DNA of a Single Individual';
const RESEARCH_TITLE = 'Phased Assembly of the WI-38 Genome';

class App extends React.Component {
  state = {
    calicoResearchHeaderBottom: 0,
    scrollTop: 0,
    titleBottom: 0,
  };

  updateScroll = (event: Object) => {
    this.setState({ scrollTop: event.target.scrollTop });
  }

  render () {
    const { calicoResearchHeaderBottom, scrollTop, titleBottom } = this.state;
    const isScrolledUp = scrollTop >= titleBottom;
    
    const renderNavigationComponent = (navigationStyle: Object, shouldDrawBackgroundAndBorder: boolean) => (
      <Navigation
        className="content-width"
        items={[
          {
            name: 'Home',
            path: '/',
          },
          {
            name: 'Methods',
            path: '/methods',
          },
          {
            name: 'Data Files',
            path: '/data',
          },
          {
            name: 'Genome Browser',
            path: '/browser',
          },
        ]}
        style={{
          backgroundColor: shouldDrawBackgroundAndBorder && Colors['grey-xxxl'],
          borderBottom: '1px solid',
          borderBottomColor: shouldDrawBackgroundAndBorder ? Colors['grey-xl'] : 'rgba(255,255,255,0)',
          top: calicoResearchHeaderBottom,
          transition: 'border-color .2s',
          ...navigationStyle,
        }}
      />
    );

    const renderInFlow = (children: Array<Object>) => {
      return (
        <main
          className="relative app flex-column"
          id="app"
          onScroll={this.updateScroll}
          style={{
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <CalicoResearchHeader
            onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
            subheader={RESEARCH_TITLE}
            showSubheader={scrollTop > titleBottom - calicoResearchHeaderBottom}
          />
          <Title
            onMeasureElement={rect => this.setState({ titleBottom: rect.bounds.bottom })}
            scrollTop={scrollTop}
            title={RESEARCH_TITLE}
          />
          {renderNavigationComponent({}, isScrolledUp)}
          <section className="flex-1" style={{ minHeight: '150vh' }}>
            {children}
            <CalicoResearchFooter />
          </section>
        </main>
      );
    };

    const renderInFixed = (children: Array<Object>) => {
      const shouldDrawBackgroundAndBorder = true;
      return (
        <main
          className="relative app flex-column"
          id="app"
          onScroll={this.updateScroll}
          style={{
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <CalicoResearchHeader
            onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
            subheader={RESEARCH_TITLE}
            showSubheader={true}
          />
          {renderNavigationComponent({ marginBottom: 0 }, shouldDrawBackgroundAndBorder)}
          <section className="flex-1 relative" style={{ overflowX: 'scroll' }}>
            {children}
          </section>
          {/*<CalicoResearchFooter />*/}
        </main>
      );
    };

    return (
      <DocumentTitle title={`${RESEARCH_TITLE} | Calico research`}>
        <div>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => renderInFlow([
                  <section className="content-width font-size-small smooth">
                    <Citation
                      citation={CITATION}
                      className="margin-bottom-2 bold"
                      showCopyCitation
                      showCopyUri
                      style={{ lineHeight: 1.35 }}
                      title={CITATION_TITLE}
                      uri={DOI}
                    />
                    <AuthorList
                      authors={Authors}
                      contactSubject={RESEARCH_TITLE}
                      showContact
                    />
                  </section>,
                  <ComposedHomePage />
                ])}
              />
              <Route
                exact
                path="/browser"
                render={() => renderInFixed([<ComposedBrowserPage />])}
              />
              <Route
                exact
                path="/data"
                render={() => renderInFlow([<ComposedDownloadsPage />])}
              />
              <Route
                exact
                path="/methods"
                render={() => renderInFlow([<ComposedMethodsPage />])}
              />
            </Switch>
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
