export default [
  {
    nameFirst: 'Ilya',
    nameLast: 'Soifer',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Nicole L.',
    nameLast: 'Fong',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Nelda',
    nameLast: 'Yi',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Andrea T.',
    nameLast: 'Ireland',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Irene',
    nameLast: 'Lam',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Matthew',
    nameLast: 'Sooknah',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Jonathan S.',
    nameLast: 'Paw',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Paul',
    nameLast: 'Peluso',
    affiliations: [{ organization: 'Pacific Biosciences' }],
    corresponding: false,
  },
  {
    nameFirst: 'Gregory T.',
    nameLast: 'Concepcion',
    affiliations: [{ organization: 'Pacific Biosciences' }],
    corresponding: false,
  },
  {
    nameFirst: 'David',
    nameLast: 'Rank',
    affiliations: [{ organization: 'Pacific Biosciences' }],
    corresponding: false,
  },
  {
    nameFirst: 'Alex R.',
    nameLast: 'Hastie',
    affiliations: [{ organization: 'Bionano Genomics' }],
    corresponding: false,
  },
  {
    nameFirst: 'Vladimir',
    nameLast: 'Jojic',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'J. Graham',
    nameLast: 'Ruby',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'David',
    nameLast: 'Botstein',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Margaret A.',
    nameLast: 'Roy',
    email: 'mroy@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
];