import React from 'react';
import { cx } from 'emotion';
import { EditorialMarkdown } from '@calico/calico-ui-editorial';

import HomeContent from '../content/Methods.md';

const HomePage = ({ className, style }) => {
  return (
    <EditorialMarkdown
      className={cx('article content-width', className)}
      content={HomeContent}
      style={style}
    />
  );
}

export default HomePage;
