import React from 'react';
import { Dataset } from '@calico/calico-ui-editorial';

import Datasets from '../content/Datasets.js';

const DownloadsPage = ({ className, style }) => {
  return (
    <div className={className} style={style}>
      <section className="article content-width">
        <div className="max-line-length margin-bottom-5">
          <h4>Datasets</h4>
          <p>
            Download the assembly statistics, assembled sequences, and variant calls.
            For more information about these files, see the paper and supplementary material.
          </p>
        </div>
        {Datasets.map((dataset, d) => {
          return (
            <Dataset
              content={dataset.content}
              format={dataset.format}
              key={d}
              name={dataset.name}
              size={dataset.size}
              src={dataset.src}
              title={dataset.title}
            />
          );
        })}
      </section>
    </div>
  );
}

export default DownloadsPage;
