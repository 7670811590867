import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import Icon from 'react-ionicons';

class GeneDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activated: false,
      value: 'Search genes...',
    }
  }

  onFocus = () => {
    this.setState({ value: '' })
  }

  onBlur = () => {
    this.setState({ value: 'Search genes...' })
  }

  render() {
    const style = {
      background: 'white',
      border: '1px solid #bababa',
      borderRadius: '6px',
      display: 'inline-block',
      fontFamily: 'Mark OT',
      padding: '5px 30px 5px 10px',
      position: 'relative',
    };
    return (
      <div className="relative">
        <ReactAutocomplete
          ref='geneDropdown'
          inputProps={{
            ref: 'geneDropdown',
            className: 'font-size-small',
            style,
            onFocus: this.onFocus,
            onBlur: this.onBlur
          }}
          menuStyle={{
            background: 'white',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            maxHeight: '50%',
            overflow: 'auto',
            position: 'fixed',
            zIndex: 129 // make dropdown appear above IGV navbar (which has zIndex = 128)
          }}
          items={this.props.geneNames}
          shouldItemRender={(item, value) => {
            return value.length >= 3 && item.toLowerCase().indexOf(value.toLowerCase()) > -1
          }}
          getItemValue={item => item}
          renderItem={(item, highlighted) =>
            <div
              key={item}
              style={{
                backgroundColor: highlighted ? '#eee' : 'transparent',
                padding: '5px 30px 5px 10px',
              }}
            >
              {item}
            </div>
          }
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onSelect={(value, item) => {
            this.setState({ value: value });
            this.props.onGeneSelect(item);
          }}
        />
        <Icon
          className="grey-l"
          fontSize="1.35em"
          icon="ios-search"
          style={{ position: 'absolute', top: 6, right: 7 }}
        />
      </div>
    )
  }
}

GeneDropdown.defaultProps = {
  geneNames: [],
  onGeneSelect: value => { console.log(value) },
};

export default GeneDropdown;
